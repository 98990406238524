<template>
    <div class="examDownloadList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="单位名称"  @keyup.enter.native=''></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click=''>查询</el-button>
                </el-form-item>

            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>

                <el-table-column prop="name" label="单位名称"  width="150" align='center' ></el-table-column>
                <el-table-column prop="area" label="课程名称" width="200" align='center' ></el-table-column>
                <el-table-column prop="address" label="教学班名称" align='center' ></el-table-column>
                <el-table-column prop="admin_count" label="试卷名称" align='center' ></el-table-column>
                <el-table-column prop="operation" align='center' label="操作" width="300">
                    <template slot-scope='scope'>
                        <el-button icon='edit' size="mini" @click=''>下载</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>

    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";

    export default {
        name: 'examDownloadList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:true, isShow:false,
                selSchool: null, selSchoolDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
            }
        },
        components:{
             Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadSchoolList();
	   },
        methods: {


            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadSchoolList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadSchoolList()
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


